import React, { useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/product/categoryValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { CategoryContext } from "../category";

axios.defaults.baseURL = API;

function CreateCategory(props) {

    const {dataList, setDataList, ProductTypeList} = useContext(CategoryContext);

    const handleInputPaymentTypeChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = dataList.filter((data) => {
            return data.Category.toLowerCase() === params.toLowerCase() &&
                   data.ProductTypeID === values.ProductTypeID
        });
        if (results.length > 0)
            handleChange("CheckCategory", results[0].Category);
        else
            handleChange("CheckCategory", "");
    }

    const handleSelectProductType = (e) => {
        var data = ProductTypeList.filter(function (item) {
            return item.ProductType == e.target.value
        })
        if (data.length > 0) {
            handleChange("ProductType", data[0].ProductType);
            handleChange("ProductTypeID", data[0].ProductTypeID);
        } else {
            handleChange("ProductType", "");
            handleChange("ProductTypeID", "");
            handleChange("CategoryID", "");
            handleChange("Category", "");
        }
    };

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            ProductTypeID: values.ProductTypeID,
            Category: values.Category,
        }
        await axios.post("/createCategory", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            var obj = {
                CategoryID: response?.data?.insertId,
                ProductType: values.ProductType,
                ProductTypeID: values.ProductTypeID,
                Category: values.Category,
            }
            dataList.push(obj);
            setDataList(dataList);
            localStorage.setItem("categoryData", JSON.stringify(dataList));
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => {
        handleChange("ProductTypeID", "");
        handleChange("ProductType", "");
        handleChange("CategoryID", "");
        handleChange("Category", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງໝວດສິນຄ້າ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>ໝວດສິນຄ້າ</span>
                                <input className={`input form-control ${errors.Category && 'is-invalid'}`} type="text" name="Category" onChange={(e) => handleInputPaymentTypeChange(e)} value={values.Category || ''} required disabled={!values.ProductTypeID} />
                                {errors.Category && (
                                    <p className="invalid-feedback">{errors.Category}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <label>ປະເພດສິນຄ້າ: </label>
                                <select name="ProductTypeID" onChange={(e) => handleSelectProductType(e)}
                                    className={`input form-control ${errors.ProductTypeID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກປະເພດສິນຄ້າ'}
                                    </option>
                                    {   ProductTypeList && ProductTypeList.map(item => (
                                        <option
                                            key={item.ProductTypeID}
                                            value={item.ProductType}
                                        >
                                            {item.ProductType}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateCategory;
