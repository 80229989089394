import React, { useState, useEffect, useRef, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../../css/form.css';
import '../../../../css/TaxReport.css';
import '../../../../css/datepicker.css';
import DatePicker from "react-datepicker";
import axios from "axios";
import logo from '../../../../img/logo/ManeeCafeLogo.jpg';
import { API } from "../../../../services/api";
import { useReactToPrint } from 'react-to-print';
axios.defaults.baseURL = API;


class PrintReport extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
      }
      const billDetails = this.props.BillDetails;
      const productList = this.props.TemProductList;
      const today = Date.now();
      let NetTotal = 0;
      let Discount = 0;
      let PaidMoreAmount = 0;
      const StartDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.StartedDate));
      const EndDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.EndedDate));
      return (
          <div className="TaxReportContent">
              <div className="ReportHeader">
                      <img className="profile-img" src={logo} />
                  <div className="ReportTitle">
                      <div className="titleHeader1">
                          <span>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>**********</span>
                      </div>
                      <div className="titleHeader3">
                          <span>ລາຍງານ ສະຫລຸບອາກອນ</span>
                      </div>
                      
                  </div>
                  <div className="ReportBillNo">
                      <span>ແຕ່ວັນທີ # {StartDate}</span>
                  </div>
                  <div className="PrintDate">
                      <span>ຫາວັນທີ: {EndDate}</span> 
                  </div>
                  {/* <div className="ReportLocation">
                      <span>ສາຂາ: {billDetails.LocationName}</span>
                  </div> */}
              </div>
              <div className="ReportBody">
                  <div className="ReportBodyHeader">
                      <span className="SubTotal">ລວມມູນຄ່າບໍ່ມີ ອພມ</span>
                      <span className="Tax">ອພມ</span>
                      <span className="NetTotal">ລວມທັງໝົດ</span>
                  </div>
                  <div className="HeadLineTax">
                        <div className="footer-line"></div>
                        <div className="footer-line"></div>
                    </div>
                  <div className="ReportDetail">
                      {
                          productList && productList.map((x, y) => {
                                return (
                                    <div className="Items">
                                        <div className="Detail">
                                            <div className="mds">
                                                <span className="SubTotal">{currencyFormat(Number(x.NetTotal) - Number(x.Tax))}</span>
                                                <span className="Tax">{currencyFormat(x.Tax)}</span>
                                                <span className="NetTotal"><div className="total">{currencyFormat(x.NetTotal)}</div></span>
                                            </div>
                                        </div>
                                    </div>
                                )
                          })
                      }
                  </div>
                  <div className="ReportBodyFooter">
                  </div>
                  <div className="ReportSign">
                      
                      <span className="Manager">ຜູ້ກວດກາ</span>
                      <span className="StockAdmin"></span>
                      <span className="finance">ຜູ້ຄິດໄລ່</span>
                  </div>
              </div>
          </div>
      );
  }
}


function TaxReport() {

  const [StartedDate, setStartedDate] = useState(null); 
  const [EndedDate, setEndedDate] = useState(null); 
  const [LocationList, setLocationList] = useState(null); 
  const [LocationID, setLocationID] = useState(""); 
  const [LocationName, setLocationName] = useState("");
  const componentRef = useRef(); 

  const [showData, setShowData] = useState([]);
  const [TransactionDetail, setTransactionDetail] = useState([]);

  const filterPassedTime = time => {
    const selectedDate = new Date(time);
    return selectedDate.getTime();
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const handleChangeStartedDate = event => {
    if (event)
        setStartedDate(event);
    else 
        setStartedDate(null);
}

const handleChangeEndedDate = event => {
    if (event)
        setEndedDate(event);
    else 
        setEndedDate(null);
}

const handleSelectLocation = (e) => {
  var data = LocationList.filter(function (item) {
      return item.LocationName == e.target.value
  })
  if (data.length > 0) {
      setLocationName(data[0].LocationName);
      setLocationID(data[0].LocationID);
  } else {
      setLocationName("");
      setLocationID("");
  }
};

  async function SearchTransaction() {
      await axios.get(`/TaxReport/${StartedDate}/${EndedDate}`).then((response) => {
        let Data = response?.data;
        setShowData(Data);
      }).catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let userData = localStorage.getItem("locationData");
    if (userData) {
      let userRole = JSON.parse(userData);
      setLocationList(userRole);
    }
  }, []);

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ລາຍງານເງິນສະຫລຸບອາກອນ</span>
          </div>
          <div className="txtSearch">
            <div className="StartedDate">
                <label>ແຕ່ວັນທີ: </label>
                <DatePicker className="date"
                    selected={StartedDate}
                    onChange={(e) => handleChangeStartedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <div className="EndedDate">
                <label >ຫາວັນທີ: </label>
                <DatePicker className="date"
                    selected={EndedDate}
                    onChange={(e) => handleChangeEndedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <button className="btnSearch btn btn-primary" onClick={() => SearchTransaction()}>
                ຄົ້ນຫາ
            </button>
            <button className="btnSearch btn btn-success" onClick={() => handlePrint()}>
                ພິມລາຍງານ
            </button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ມູນຄ່າບໍ່ມີ ອພມ</th>
                <th>ອພມ</th>
                <th>ລວມທັງໝົດ</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{Number(x.NetTotal) - Number(x.Tax)}</td>
                        <td >{x.Tax}</td>
                        <td >{x.NetTotal}</td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'none'}}>
            <PrintReport ref={componentRef}
                    TemProductList={showData}
                    BillDetails={{"StartedDate": StartedDate, "EndedDate": EndedDate , "LocationName": LocationName}} 
            />
        </div>
      </div>
  );
};

export default TaxReport;
