import React, { useState, useContext, useEffect } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/product/brandValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { BrandContext } from "../brand";

axios.defaults.baseURL = API;

function CreateBrand(props) {

    const {dataList, setDataList, ProductTypeList, CategoryList} = useContext(BrandContext);
    const [categoryData, setCategoryData] = useState([]);
    
    const handleInputPaymentTypeChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = dataList.filter((data) => {
            return data.Brand.toLowerCase() === params.toLowerCase() &&
                   data.CategoryID === values.CategoryID
        });
        if (results.length > 0)
            handleChange("CheckBrand", results[0].Brand);
        else
            handleChange("CheckBrand", "");
    }

    const handleSelectProductType = (e) => {
        var data = ProductTypeList.filter(function (item) {
            return item.ProductType == e.target.value
        })
        if (data.length > 0) {
            var obj = CategoryList.filter(function (item) {
                return item.ProductTypeID == data[0].ProductTypeID
            })
            setCategoryData(obj);
            handleChange("ProductType", data[0].ProductType);
            handleChange("ProductTypeID", data[0].ProductTypeID);
        } else {
            handleChange("ProductType", "");
            handleChange("ProductTypeID", "");
            handleChange("Category", "");
            handleChange("CategoryID", "");
            handleChange("Brand", "");
        }
    };

    const handleSelectCategory = (e) => {
        var data = categoryData.filter(function (item) {
            return item.Category == e.target.value
        })
        if (data.length > 0) {
            handleChange("Category", data[0].Category);
            handleChange("CategoryID", data[0].CategoryID);
        } else {
            handleChange("Category", "");
            handleChange("CategoryID", "");
            handleChange("Brand", "");
        }
    };

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            ProductTypeID: values.ProductTypeID,
            CategoryID: values.CategoryID,
            Brand: values.Brand,
        }
        await axios.post("/createBrand", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            var obj = {
                BrandID: response?.data?.insertId,
                ProductType: values.ProductType,
                ProductTypeID: values.ProductTypeID,
                CategoryID: values.CategoryID,
                Category: values.Category,
                Brand: values.Brand,
            }
            dataList.push(obj);
            setDataList(dataList);
            localStorage.setItem("brandData", JSON.stringify(dataList));
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => {
        handleChange("ProductTypeID", "");
        handleChange("ProductType", "");
        handleChange("CategoryID", "");
        handleChange("Category", "");
        handleChange("BrandID", "");
        handleChange("Brand", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    useEffect(() => {
        setCategoryData(CategoryList);
      }, [CategoryList]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງຫຍີ່ຫໍ້</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>ຫຍີ່ຫໍ້</span>
                                <input className={`input form-control ${errors.Brand && 'is-invalid'}`} type="text" name="Brand" onChange={(e) => handleInputPaymentTypeChange(e)} value={values.Brand || ''} required disabled={!values.CategoryID} />
                                {errors.Brand && (
                                    <p className="invalid-feedback">{errors.Brand}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <label>ປະເພດສິນຄ້າ: </label>
                                <select name="ProductTypeID" onChange={(e) => handleSelectProductType(e)}
                                    className={`input form-control ${errors.ProductTypeID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກປະເພດສິນຄ້າ'}
                                    </option>
                                    {   ProductTypeList && ProductTypeList.map(item => (
                                        <option
                                            key={item.ProductTypeID}
                                            value={item.ProductType}
                                        >
                                            {item.ProductType}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ໝວດສິນຄ້າ: </label>
                                <select name="CategoryID" onChange={(e) => handleSelectCategory(e)}
                                    className={`input form-control ${errors.CategoryID && 'is-invalid'}`} disabled={!values.ProductTypeID}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກໝວດສິນຄ້າ'}
                                    </option>
                                    {   categoryData && categoryData.map(item => (
                                        <option
                                            key={item.CategoryID}
                                            value={item.Category}
                                        >
                                            {item.Category}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateBrand;
