import React, { useState, useEffect, useRef, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../../css/form.css';
import '../../../../css/CashReport.css';
import '../../../../css/datepicker.css';
import DatePicker from "react-datepicker";
import axios from "axios";
import { API } from "../../../../services/api";
import logo from '../../../../img/logo/ManeeCafeLogo.jpg';
import { useReactToPrint } from 'react-to-print';
axios.defaults.baseURL = API;


class PrintReport extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
      }
      const billDetails = this.props.BillDetails;
      const productList = this.props.TemProductList;
      const today = Date.now();
      let NetTotal = 0;
      let Discount = 0;
      let PaidMoreAmount = 0;
      const StartDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.StartedDate));
      const EndDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.EndedDate));
      return (
          <div className="CashReportContent">
              <div className="ReportHeader">
                    <img className="profile-img" src={logo} />
                  <div className="ReportTitle">
                      <div className="titleHeader1">
                          <span>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>**********</span>
                      </div>
                      <div className="titleHeader3">
                          <span>ລາຍງານ ສະຫລຸບເງິນຂາຍສົ່ງ</span>
                      </div>
                      
                  </div>
                  <div className="ReportBillNo">
                      <span>ແຕ່ວັນທີ # {StartDate}</span>
                  </div>
                  <div className="PrintDate">
                      <span>ຫາວັນທີ: {EndDate}</span> 
                  </div>
                  {/* <div className="ReportLocation">
                      <span>ສາຂາ: {billDetails.LocationName}</span>
                  </div> */}
              </div>
              <div className="ReportBody">
                  <div className="ReportBodyHeader">
                  <span className="CountNumber">NO.</span>
                      <span className="PaymentType">ປະເພດຈ່າຍ</span>
                      <span className="SubTotal">ຍອດທັງໝົດ</span>
                      <span className="Discount">ສ່ວນຫລຸດ</span>
                      <span className="PaidAmount">ຍອດຈ່າຍ</span>
                  </div>
                  <div className="HeadLineCash">
                    <div className="footer-line"></div>
                    <div className="footer-line"></div>
                  </div>
                  
                  <div className="ReportDetail">
                      {
                          productList && productList.map((x, y) => {
                            NetTotal = Number(x.NetTotal) +  Number(NetTotal);
                            Discount =  Number(x.Discount) +  Number(Discount);
                            PaidMoreAmount =  Number(x.PaidMoreAmount) +  Number(PaidMoreAmount);
                            return (
                                <div className="Items">
                                    <div className="Detail">
                                        <div className="mds">
                                            <span className="NO">{(y + 1) }</span>
                                            <span className="PaymentType">{x.PaymentType}</span>
                                            <span className="SubTotal">{currencyFormat(x.NetTotal)}</span>
                                            <span className="Discount">{currencyFormat(x.Discount)}</span>
                                            <span className="PaidAmount"><div className="total">{currencyFormat(x.PaidMoreAmount)}</div></span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                      }
                  </div>
                  <div className="HeadLineCash">
                    <div className="footer-line"></div>
                    <div className="footer-line"></div>
                  </div>
                  <div className="ReportBodyFooter">
                      <span className="Total">ລວມທັງໝົດ:</span>
                      <span className="SubTotal">{currencyFormat(NetTotal)}</span>
                      <span className="Discount">{currencyFormat(Discount)}</span>
                      <span className="PaidAmount">{currencyFormat(PaidMoreAmount)}</span>
                  </div>
                  <div className="Footer">
                      <span className="f1">ເງິນສົດຈາກຮ້ານ:</span>
                      <span className="f2">ລາຍຈ່າຍ:</span>
                      <span className="f3">ລວມຍອດເງິນ:</span>
                  </div>
                  <div className="ReportSign">
                      
                      <span className="Manager">ຜູ້ກວດກາ</span>
                      <span className="StockAdmin"></span>
                      <span className="finance">ຜູ້ຄິດໄລ່</span>
                  </div>
              </div>
          </div>
      );
  }
}


function CashReport_wholesale_price() {

  const [StartedDate, setStartedDate] = useState(null); 
  const [EndedDate, setEndedDate] = useState(null); 
  const componentRef = useRef(); 

  const [showData, setShowData] = useState([]);
  const [TransactionDetail, setTransactionDetail] = useState([]);

  const filterPassedTime = time => {
    const selectedDate = new Date(time);
    return selectedDate.getTime();
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const handleChangeStartedDate = event => {
    if (event)
        setStartedDate(event);
    else 
        setStartedDate(null);
}

const handleChangeEndedDate = event => {
    if (event)
        setEndedDate(event);
    else 
        setEndedDate(null);
}


  async function SearchTransaction() {
      await axios.get(`/CashReport_wholesale_price/${StartedDate}/${EndedDate}`).then((response) => {
        let Data = response?.data;
        setShowData(Data);
        console.log(Data)
      }).catch((error) => {
        console.log(error);
      });
  };

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ລາຍງານເງິນສະຫລຸບເງິນ</span>
          </div>
          <div className="txtSearch">
            <div className="StartedDate">
                <label>ແຕ່ວັນທີ: </label>
                <DatePicker className="date"
                    selected={StartedDate}
                    onChange={(e) => handleChangeStartedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <div className="EndedDate">
                <label >ຫາວັນທີ: </label>
                <DatePicker className="date"
                    selected={EndedDate}
                    onChange={(e) => handleChangeEndedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <button className="btnSearch btn btn-primary" onClick={() => SearchTransaction()}>
                ຄົ້ນຫາ
            </button>
            <button className="btnSearch btn btn-success" onClick={() => handlePrint()}>
                ພິມລາຍງານ
            </button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ປະເພດຈ່າຍ</th>
                <th>ຍອດລວມ</th>
                <th>ສ່ວນຫລຸດ</th>
                <th>ຈ່າຍເພີ່ມ</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.PaymentType}</td>
                        <td >{x.NetTotal}</td>
                        <td >{x.Discount}</td>
                        <td >{x.PaidMoreAmount}</td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'none'}}>
            <PrintReport ref={componentRef}
                    TemProductList={showData}
                    BillDetails={{"StartedDate": StartedDate, "EndedDate": EndedDate }} 
            />
        </div>
      </div>
  );
};

export default CashReport_wholesale_price;
