import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { API } from "../../../../services/api";
import '../../../../css/posOrder.css';
import logo from '../../../../img/logo/ManeeCafeLogo.jpg';
import { POSContent } from "../pos_retail_price";
import { UserContext } from "../../../../App";

axios.defaults.baseURL = API;

function Posorder_retail_price() {

    const {userID, LocationID} = useContext(UserContext);
    const {QueueNO, setShowPayment, showOrder, TempOrder, setTempOrder, setShowOrder, subTotal, subTotalQty, setSubTotalQty, setSubTotal, setNetTotal, setTax} = useContext(POSContent);
    const [menuList, setMenuList] = useState([]);
    const [subMenuData, setSubMenuData] = useState([]);
    const [productBySubMenuData, setProductBySubMenuData] = useState([]);
    const [subMenuList, setSubMenuList] = useState([]);
    const [productList, setProductList] = useState([]);

    const [Barcode, setBarcode] = useState([]);
    const [Quantity, setQuantity] = useState(1);

    const [ProductType, setProductType] = useState([]);

    function currencyFormat(num) {
        if (num !== "" && num > 0) {
            return Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    }

    async function handleClickMenuToGetSubmenu(MenuID, ProductType) {
        var list = [];
        for (var i=0; i < subMenuData.length; i++) {
            if (subMenuData[i].MenuID == MenuID) {
                setProductType(ProductType);
                list.push({
                    CategoryID: subMenuData[i].CategoryID,
                    Category: subMenuData[i].Category,
                });
            }
        }  
        setSubMenuList(list);
        setProductList([]);
    };

    const handleInputChange = event => {
        setQuantity(event.target.value);
    };

    const handleClickSubMenuToGetProductList = (CategoryID) => {
       var list = [];
        for (var i=0; i < productBySubMenuData.length; i++) {
            if (productBySubMenuData[i].CategoryID == CategoryID) {
                list.push({
                    ProductID: productBySubMenuData[i].ProductID,
                    LaoName: productBySubMenuData[i].LaoName,
                    EngName: productBySubMenuData[i].EngName,
                    Barcode: productBySubMenuData[i].Barcode,
                    ImgUrl: productBySubMenuData[i].ImgUrl,
                    Retail_Price: productBySubMenuData[i].Retail_Price,
                });
            }
        }  
        setProductList(list);
    }

    async function addOrder(ProductID, Retail_Price, LaoName, EngName, qty) {
        var c =0;
        for (var i=0; i < TempOrder.length; i++) {
            if (TempOrder[i].ProductID == ProductID) {
                if(Number(TempOrder[i].Quantity) + Number(qty) > 0)
                {
                    TempOrder[i].Quantity = Number(TempOrder[i].Quantity) + Number(qty);
                    TempOrder[i].SubTotal = Number(TempOrder[i].Quantity) * Number(TempOrder[i].Retail_Price);
                } else {
                    TempOrder.splice(i, 1);
                }
                c = 1;
                break;
            }
        }  
        if(c == 0){
            var datalist = {
                SubTotal: Retail_Price,
                ProductID: ProductID,
                LaoName: LaoName,
                EngName: EngName,
                Retail_Price: Retail_Price,
                QueueNO: QueueNO,
                Quantity: Number(qty),
                StatusID : 1,
                ProductType: ProductType,
            }
            TempOrder.push(datalist);
        }
        getSubtotal();
    }

    async function handleSearchProduct (e) {
        let token = localStorage.getItem("token");
        setBarcode(e.target.value);
        if(e.target.value){
            var data = {
                Barcode: e.target.value
            }
            await axios.post("/getProductByBarcode", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                let productData = response?.data;
                if(productData.length > 0){
                    addOrder(productData[0].ProductID, productData[0].Retail_Price, productData[0].LaoName, productData[0].EngName, Quantity);
                    setBarcode("");
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    const getSubtotal = () => {
        if (TempOrder) {
            if(TempOrder.length > 0){
                var gSubTotal = 0;
                let gSubTotalQty = 0;
                for (var i=0; i < TempOrder.length; i++) {
                    gSubTotal = Number(gSubTotal) + Number(TempOrder[i].SubTotal);
                    gSubTotalQty = Number(gSubTotalQty) + Number(TempOrder[i].Quantity);
                }  
                setSubTotal(gSubTotal);
                setNetTotal(gSubTotal);
                setSubTotalQty(gSubTotalQty);
                var tax = (Number(gSubTotal) -((Number(gSubTotal) * 100) /107)).toFixed(2);
                setTax(tax);
            } else{
                setSubTotal(0);
                setNetTotal(0);
                setTax(0);
            }

        } else{
            setSubTotal(0);
            setNetTotal(0);
            setTax(0);
        }
    }

    const handleShowPayment = () => {
       if(TempOrder.length > 0){
        setShowPayment(true);
       }
    }


    useEffect(() => {
        let menudata = localStorage.getItem("menuData");
        if (menudata) {
          let menu = JSON.parse(menudata);
          setMenuList(menu);
        }
        let subMenuData = localStorage.getItem("subMenuData");
        if (subMenuData) {
            let submenu = JSON.parse(subMenuData);
            setSubMenuData(submenu);
        }
        let productBySubMenuData = localStorage.getItem("productBySubMenuData");
        if (productBySubMenuData) {
            let productBySubMenu = JSON.parse(productBySubMenuData);
            setProductBySubMenuData(productBySubMenu);
        }
      }, []);

    return (
        <div style={{ display: showOrder ? 'flex' : 'none' }} className="formPos">
            <div className="OrderContent">
                <div className="LeftContent">
                    <div className="imgLogo">
                        <img className="logo" src={logo} />
                    </div>
                    <div className="SubMenu">
                        {
                            subMenuList && subMenuList.map((x, y) => {
                                return (
                                    <div key={y} className="SubMenuContent" onClick={() => handleClickSubMenuToGetProductList(x.CategoryID)}>
                                        <img />
                                        <p className="name">{x.Category}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="CenterContent">
                    <div className="CenterHeader">
                        {/* <div className="user-name">{serverName}</div> */}
                        <div className="Search">
                            <div className="Barcode">
                                <span>Barcode</span>
                                <input type="text" className="form-control" placeholder="Search" onChange={(e) => handleSearchProduct(e)} value={Barcode} />
                            </div>
                            <div className="Qty">
                                <span>Quantity</span>
                                <input type="text" className="form-control" placeholder="1" onChange={(e) => handleInputChange(e)} value={Quantity}/>
                            </div>
                        </div>
                    </div>
                    <div className="menuContent">
                        {
                            menuList && menuList.map((x, y) => {
                                return (
                                    <div key={y} className="FoodMenu" onClick={() => handleClickMenuToGetSubmenu(x.MenuID, x.ProductType)}>
                                        <div className="FoodMenuContent" tabIndex={y}  >
                                            <div className="item-header">
                                                <img />
                                            </div>
                                            <div className="item-body">
                                                <p className="name">{x.ProductType}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="underLine">
                        <hr />
                        <span>{} Menu</span>
                    </div>
                    <div className="MenuList">
                    {
                            productList && productList.map((x, y) => {
                                return (
                                    <div key={y} className="item" onClick={() => addOrder(x.ProductID, x.Retail_Price, x.LaoName, x.EngName, 1)}>
                                        <div className="item-header">
                                            <img src={x.ImgUrl} className="profile-img" />
                                        </div>
                                        <div className="item-body">
                                            <div className="name">
                                                <p>{x.LaoName}</p>
                                            </div>
                                            <p className="price">{currencyFormat(x.Retail_Price)}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="RightContent">
                    <div className="billNo">
                        <div className="ordertext"><p>Order List</p></div>
                        <div className="tableNo"><p>QueueNO # {QueueNO} </p></div>
                        <div className="btnclose" onClick={e => setShowOrder(false)} >X</div>
                    </div>
                    <div className="OrderHead">
                        <div className="ProductName"><p>Price</p></div>
                        <div className="Quantity">Quantity</div>
                        <div className="Price">Total</div>
                    </div>
                    <div className="posRightSidenavContent">
                        {
                            TempOrder && TempOrder.map((x, y) => {
                                return (
                                    <div key={y} className="orderContent">
                                        <div className="orderFooter">
                                            <div className="price">
                                                <p>{currencyFormat(x.Retail_Price)}</p>
                                            </div>
                                            <div className="plus" onClick={() => addOrder(x.ProductID, x.Retail_Price, x.LaoName, x.EngName, 1)}>
                                                +
                                            </div>
                                            <div className="quantity">
                                                <p>{currencyFormat(x.Quantity)}</p>
                                            </div>
                                            <div className="minus" onClick={() => addOrder(x.ProductID, x.Retail_Price, x.LaoName, x.EngName, -1)}>
                                                -
                                            </div>
                                            <div className="Subtotal">
                                                <p>{currencyFormat(x.Quantity * x.Retail_Price)}</p>
                                            </div>
                                        </div>
                                        <div className="orderBody">
                                            <div className="detail">
                                                <div className="productName">
                                                    <span>{y+1}. {x.LaoName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="posRightSidenavBottom">
                        <div className="footer-subtotal">
                            <div className="footer-line"></div>
                            <div className="nettotal">
                                <span>Sutotal:</span>
                                <strong>{currencyFormat(subTotalQty)}</strong>
                                <strong>{currencyFormat(subTotal)}</strong>
                            </div>
                        </div>
                        <div className="paymentMethod">
                            <div className="btnpayment" onClick={(e) => handleShowPayment()}>
                                <span>Payment</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Posorder_retail_price;
